import { StatusMessage } from 'components';
import RemoteAppNotEnabled from './components/RemoteAppNotEnabled/RemoteAppNotEnabled';
import TableActions from './components/TableActions/TableActions';
import AppsHeader from './components/AppsHeader/AppsHeader';
import ToggleRemoteAppsModal from './components/ToggleRemoteAppsModal';
import { IDomain } from 'interfaces';
import { IModalData } from 'pages/ResourceAssignments/ResourceAssignments';
import AppAssignmentsTable from './components/AppAssignmentsTable/AppAssignmentsTable';
import { useAppSelector } from 'store';
import useModal from 'hooks/useModal';

interface Props {
  domain: IDomain;
  openManageAssignmentsModal: (data: IModalData) => void;
}

const AppAssignments = ({ domain, openManageAssignmentsModal }: Props) => {
  const { isError, error } = useAppSelector((state) => state.resourceAssignments.getAppAssignmentsRequest);
  const {
    isOpen: showToggleRemoteAppsModal,
    openModal: openToggleRemoteAppsModal,
    closeModal: closeToggleRemoteAppsModal,
  } = useModal();

  const { isAppPublishingEnabled } = domain;

  if (isError) {
    return <StatusMessage error>{error}</StatusMessage>;
  }

  return (
    <>
      <AppsHeader
        isAppPublishingEnabled={isAppPublishingEnabled}
        openToggleRemoteAppsModal={openToggleRemoteAppsModal}
        domain={domain}
      />

      {isAppPublishingEnabled && <TableActions domainId={domain.id} isAppPublishingEnabled={isAppPublishingEnabled} />}

      <div>
        {isAppPublishingEnabled ? (
          <AppAssignmentsTable domain={domain} openManageAssignmentsModal={openManageAssignmentsModal} />
        ) : (
          <RemoteAppNotEnabled />
        )}
      </div>

      {showToggleRemoteAppsModal && (
        <ToggleRemoteAppsModal
          isOpen={showToggleRemoteAppsModal}
          onRequestClose={closeToggleRemoteAppsModal}
          domain={domain}
        />
      )}
    </>
  );
};

export default AppAssignments;

import { useState, useEffect } from 'react';
import cn from 'classnames';
import ReactTooltip from 'react-tooltip';
import { checkClickOnceXtnLinks, runConnector, getUserRole, notify } from 'utils';
import { Button, Link, StatusMessage, Table } from 'components';
import {
  GroupMachineIcon,
  SingleMachineIcon,
  AppDefaultIcon,
  SecureConnectIcon,
  AzureJoinedMashineIcon,
} from 'assets/img';
import { corpTechUser, custUser, personalUser, spTechUser, techAdmin } from 'consts';
import WorkspaceEula from './components/WorkspaceEula/WorkspaceEula';
import styles from './WorkspaceTable.module.scss';
import BrowserExtensionModal from './components/BrowserExtensionModal';
import DropdownFilterBy from './components/DropdownFilterBy/DropdownFilterBy';
import { useAppDispatch, useAppSelector } from 'store';
import { acceptEula } from 'store/slices/userSlice';
import {
  getConnectorUri,
  getRdpExtensionId,
  IGetConnectorUriPayload,
  setCurrentDeviceLoading,
} from 'store/slices/workspace';
import { IDomainApp, IDomainMachine, IQueryConfig } from 'interfaces';
import { AsyncThunk, SerializedError } from '@reduxjs/toolkit';

interface TableData {
  queryConfig: IQueryConfig;
}

interface Props<T extends TableData> {
  tableData: T;
  isComputers: boolean;
  myDomainPage?: string;
  isAzureAd?: boolean;
  dispatchAction?: AsyncThunk<any, any, any>;
  isCustomerDomain?: boolean;
  tableWithAlert?: boolean;
}

const WorkspaceTable = <T extends TableData>({
  tableData,
  isComputers,
  myDomainPage,
  isAzureAd,
  dispatchAction,
  isCustomerDomain,
  tableWithAlert,
}: Props<T>) => {
  const { currentDeviceLoading } = useAppSelector((state) => state.workspace);
  const { selectedDomain } = useAppSelector((state) => state.customerDomains);
  const dispatch = useAppDispatch();

  const [webRdpExtnId, setWebRdpExtnId] = useState('');
  const [modalMessage, setModalMessage] = useState('');
  const [idForEula, setIdForEula] = useState<any[]>([]);
  const [showBrowserExtensionModal, setShowBrowserExtensionModal] = useState(false);
  const { user } = useAppSelector((state) => state.user);

  const queryConfig = tableData.queryConfig;
  const role = getUserRole(user);
  const isAssignedVisible = ![spTechUser, custUser, corpTechUser, techAdmin].includes(role);
  const showConnectButton = (exceedsLimit: boolean) => (personalUser === role ? !exceedsLimit : true);

  useEffect(() => {
    const fetchRdpExtensionId = async () => {
      await dispatch(getRdpExtensionId()).unwrap();
    };
    const savedWebRdpExtnId = sessionStorage.getItem('webRdpExtnId');
    if (!savedWebRdpExtnId) {
      fetchRdpExtensionId();
    } else {
      setWebRdpExtnId(savedWebRdpExtnId);
    }
  }, []);

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const openBrowserExtensionModal = () => {
    setShowBrowserExtensionModal(true);
  };

  const closeBrowserExtensionModal = () => {
    setShowBrowserExtensionModal(false);
    setModalMessage('');
  };

  const startRunConnector = async (machineId: string, appName?: string, appId?: string) => {
    try {
      dispatch(setCurrentDeviceLoading(appName ? appId : machineId));

      let options: IGetConnectorUriPayload = {
        machineId,
        userLogin: localStorage['TG.SSO.LOGIN'],
        passwordHash: localStorage['TG.SSO.PWD'],
      };

      if (appName) options = { ...options, appName };

      const { connectorUri } = await dispatch(getConnectorUri(options)).unwrap();
      runConnector(connectorUri);
    } catch (err: any) {
      if ((err as SerializedError).code === '403')
        notify.error(
          'All licenses are currently in use for Remote Sessions. Please contact your Company Administrator to add more licenses for your domain',
          6000,
        );
      if ((err as SerializedError).code === '406')
        notify.error('Rapid clicks are disabled. Please wait 20 seconds to try again or contact Customer Care', 6000);
      if ((err as SerializedError).code === '405') notify.error(err.message, 6000);
    } finally {
      setTimeout(() => {
        dispatch(setCurrentDeviceLoading(''));
      }, 20000);
    }
  };

  const connectDevice = (machineId: string, appName?: string, appId?: string, confirmedModal?: boolean) => {
    if (user.isEulaAccepted || confirmedModal) {
      checkClickOnceXtnLinks(
        webRdpExtnId,
        () => startRunConnector(machineId, appName, appId),
        (msg: any) => {
          setModalMessage(msg);
          openBrowserExtensionModal();
        },
      );
    } else {
      setIdForEula([machineId, appName, appId]);
    }
  };

  const onEulaConfirm = async () => {
    try {
      const idForEulaCopy = [...idForEula];
      setIdForEula([]);
      await dispatch(acceptEula()).unwrap();
      connectDevice(idForEulaCopy[0], idForEulaCopy[1], idForEulaCopy[2], true);
    } catch (err: any) {
      notify.error(err.message);
    }
  };

  const onChangeView = async (value: string) => {
    const queryConfig = {
      pageNumber: 1,
      kind: value.split(' ')[0],
    };
    if (dispatchAction) {
      await dispatch(dispatchAction({ query: queryConfig, filter: true })).unwrap();
    }
  };

  const computersColumns = [
    {
      name: 'Computer Name',
      nameFilter: myDomainPage === 'myDomainWithFilter' &&
        !isAzureAd &&
        role !== corpTechUser &&
        role !== spTechUser && (
          <DropdownFilterBy
            id="workspace-select-filter-by"
            options={['Pooled Computers', 'NonPooled Computers', 'All Computers']}
            defaultValue={`${queryConfig.kind} Computers`}
            onChange={(value) => onChangeView(value)}
          />
        ),
      width: '48%',
      sortable: 'hostname',
      data: ({ isPooled, reportedBy, isAzureJoined, hostname }: IDomainMachine) => {
        const itemIcon = isPooled ? (
          <GroupMachineIcon />
        ) : !reportedBy ? (
          <SingleMachineIcon />
        ) : isAzureJoined ? (
          <AzureJoinedMashineIcon />
        ) : (
          <SecureConnectIcon />
        );
        return (
          <span className={styles.workspaceTableHostname}>
            {itemIcon} {hostname}
          </span>
        );
      },
    },
    {
      name: 'Status',
      width: '15%',
      data: ({ isActive }: IDomainMachine) =>
        isActive ? (
          <span className={cn(styles.workspaceTableStatus, styles.workspaceTableStatusOnline)}>Online</span>
        ) : (
          <span className={cn(styles.workspaceTableStatus, styles.workspaceTableStatusOffline)}>Offline</span>
        ),
    },
    {
      name: 'IP',
      width: '15%',
      data: ({ ip }: IDomainMachine) => ip || 'N/A',
    },
    {
      name: '',
      width: '22%',
      data: ({ id, exceedsLimit }: IDomainMachine) =>
        !(currentDeviceLoading && currentDeviceLoading !== id) &&
        showConnectButton(exceedsLimit || false) && (
          <Button
            type="button"
            id="run-rdp-connection"
            size="32"
            variant="primary"
            isDisabled={!!currentDeviceLoading}
            isLoading={currentDeviceLoading === id}
            onClick={() => connectDevice(id)}
            className={styles.workspaceTableButton}>
            Connect
          </Button>
        ),
    },
  ];

  const appColumns = [
    {
      name: 'App Name',
      data: ({ icon, appName }: IDomainApp) => (
        <span className={styles.workspaceTableIcon}>
          {icon ? <img src={`data:image/png;base64,${icon}`} alt="App Icon" /> : <AppDefaultIcon />}{' '}
          {appName || 'Noname App'}
        </span>
      ),
      sortable: 'appname',
      width: '48%',
    },
    {
      name: 'Group Name',
      data: ({ name, resourceName }: IDomainApp) => name || resourceName,
      sortable: 'resourcename',
      width: '20%',
    },
    {
      name: '',
      data: ({ id, resourceId, appName }: IDomainApp) =>
        !(currentDeviceLoading && currentDeviceLoading !== id) && (
          <Button
            type="button"
            id="run-app-connection"
            size="32"
            variant="primary"
            isDisabled={!!currentDeviceLoading}
            isLoading={currentDeviceLoading === id}
            onClick={() => connectDevice(resourceId, appName, id)}
            className={styles.workspaceTableButton}>
            Connect
          </Button>
        ),
      width: '32%',
    },
  ];

  const classNameRow = ({ id }: { id: string }) =>
    cn({
      [styles.workspaceTableRowLoading]: currentDeviceLoading === id,
    });

  const noData = (
    <StatusMessage header="No computers reporting in">
      {isAssignedVisible ? (
        <p>
          Change view or{' '}
          <Link href="https://www.trugrid.com/docs/installsentry" external bold>
            add computers
          </Link>
        </p>
      ) : (
        <p>Change view</p>
      )}
    </StatusMessage>
  );

  const tableAction = async (query: IQueryConfig) => {
    if (!dispatchAction) return;
    if (isCustomerDomain) {
      query = { ...query, domainId: selectedDomain?.id };
    }
    await dispatch(dispatchAction({ query: query })).unwrap();
  };

  return (
    <>
      <Table
        data={tableData as any}
        columns={isComputers ? computersColumns : appColumns}
        id="workspace-table"
        noDataOnlyBody
        noData={noData}
        dataKey="id"
        className={cn(styles.workspaceTable, { [styles.alert]: tableWithAlert })}
        classNameRow={(item: T) => classNameRow(item as any)}
        dispatchAction={tableAction}
        rowTip={(item) =>
          isComputers && (item as unknown as IDomainMachine).isActive === false
            ? 'This computer is marked OFFLINE either because it is shut down or RDP is not accessible'
            : null
        }
      />

      <ReactTooltip id="info-tooltip" type="light" effect="solid" delayShow={200} offset={{ top: -16 }} />

      {!!idForEula.length && (
        <WorkspaceEula isOpen={!!idForEula.length} onClose={() => setIdForEula([])} onConfirm={onEulaConfirm} />
      )}

      {showBrowserExtensionModal && (
        <BrowserExtensionModal
          isOpen={showBrowserExtensionModal}
          onRequestClose={closeBrowserExtensionModal}
          modalMessage={modalMessage}
        />
      )}
    </>
  );
};

export default WorkspaceTable;

import { useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import Modal from 'components/Modal/Modal';
import styles from './RouteLeavingGuard.module.scss';

interface Location {
  pathname: string;
  state: any;
}

interface Props {
  when: boolean;
  navigate: (location: Location) => void;
  shouldBlockNavigation: () => boolean;
  modalConfig: {
    title: string;
    label: string;
    btnClose: string;
    btnAccept: string;
    message: string;
  };
}

const RouteLeavingGuard = ({ when, navigate, shouldBlockNavigation, modalConfig }: Props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate({ pathname: lastLocation.pathname, state: lastLocation.state });
    }
  }, [confirmedNavigation, lastLocation, navigate]);

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleBlockedNavigation = (nextLocation: any) => {
    if (!confirmedNavigation && shouldBlockNavigation()) {
      setModalVisible(true);
      setLastLocation({
        pathname: nextLocation.pathname,
        state: nextLocation.state,
      });
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <Modal
        isOpen={modalVisible}
        onRequestClose={closeModal}
        contentLabel={modalConfig?.title}
        cancelTitle={modalConfig?.btnClose}
        cancelButtonClass={styles.modalCancel}
        actionFunc={handleConfirmNavigationClick}
        actionTitle={modalConfig?.btnAccept}
        variantBtn="deleteOutline">
        {modalConfig?.message}
      </Modal>
    </>
  );
};

export default RouteLeavingGuard;

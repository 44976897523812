import { ArrowLeftIcon } from 'assets/img';
import { Button, DomainStatusMsg } from 'components';
import { getDomainStatus, getPageName, isSentryDeleted } from 'helpers';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { getUserRole, truncateString } from 'utils';
import { IDomain } from 'interfaces';
import styles from './withCustomers.module.scss';
import { useAppDispatch, useAppSelector } from 'store';
import { updateCustomerDomain } from 'store/slices/customerDomains';

type CustomerType = {
  domain: IDomain;
  isCompanyPage: boolean;
  userRole: string;
  isDomainNotActivated: boolean;
};

export const withCustomers = (Component: React.FunctionComponent<CustomerType>) => () => {
  const history = useHistory();
  const { pathname } = useLocation();

  const { selectedDomain: domain } = useAppSelector((state) => state.customerDomains);

  if (!domain) {
    return <Redirect to="/customer-management" />;
  }

  const { user } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const { isDomainNotActivated } = getDomainStatus(domain);
  const sentryDeleted = isSentryDeleted(domain);
  const userRole = getUserRole(user);

  const onBack = () => {
    history.push('/customer-management');
    dispatch(updateCustomerDomain(null));
  };

  const pageName = getPageName(pathname);

  if (isDomainNotActivated && !pathname.includes('device-management') && !pathname.includes('domain-management')) {
    return (
      <div className={styles.customers}>
        <div>
          <div className={styles.customersHeader}>
            <div className={styles.customersWrap}>
              <Button variant="link" icon={<ArrowLeftIcon />} className={styles.customersWrapButton} onClick={onBack}>
                Back to Customer Domains
              </Button>
              <h3 className={styles.customersTitle}>({truncateString(domain?.dnsName, false, 80)})</h3>
            </div>
          </div>
          <DomainStatusMsg domainData={domain} isSentryDeleted={sentryDeleted} pageName={pageName} />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.customers}>
      <div className={styles.customersDomain}>
        {!(
          pageName === 'Remember me' ||
          pageName === 'Shortcut Management' ||
          pageName === 'Azure Management' ||
          pageName === 'Timeout Management' ||
          pageName === 'Password Management' ||
          pageName === 'Phone Management'
        ) && (
          <div className={styles.customersHeader}>
            <div className={styles.customersWrap}>
              <Button variant="link" icon={<ArrowLeftIcon />} className={styles.customersWrapButton} onClick={onBack}>
                Back to Customer Domains
              </Button>
              <h3 className={styles.customersTitle}>({truncateString(domain?.dnsName, false, 80)})</h3>
            </div>
          </div>
        )}
        <Component
          domain={domain}
          isCompanyPage={false}
          userRole={userRole}
          isDomainNotActivated={isDomainNotActivated}
        />
      </div>
    </div>
  );
};

import { Button, Table } from 'components';
import { useAppDispatch, useAppSelector } from 'store';
import { getActiveDirectoryData, IActiveDirectoryUser } from 'store/slices/activeDirectory';
import { IQueryConfig } from 'interfaces';
import styles from './DataTable.module.scss';

interface Props {
  openModal: (data: IActiveDirectoryUser) => void;
}

const DataTable = ({ openModal }: Props) => {
  const dispatch = useAppDispatch();
  const { activeDirectoryData } = useAppSelector((state) => state.activeDirectory);

  const dispatchAction = async (query: IQueryConfig) => {
    await dispatch(getActiveDirectoryData({ query, _background: true })).unwrap();
  };

  const columns = [
    {
      name: 'Name',
      sortable: 'name',
      width: '30%',
      search: 'User Name',
      data: ({ name }: IActiveDirectoryUser) => name || 'N/A',
    },
    {
      name: 'Assigned Domains',
      width: '30%',
      data: 'assignmentsCount',
    },
    {
      name: '',
      width: '40%',
      data: (user: IActiveDirectoryUser) =>
        !activeDirectoryData?.isNoCustomerDomains && (
          <Button
            id="manage-assignments"
            type="button"
            variant="primary"
            size="32"
            onClick={() => openModal(user)}
            className={styles.tableButton}>
            Manage Assignments
          </Button>
        ),
    },
  ];

  return (
    <Table<IActiveDirectoryUser>
      columns={columns}
      data={activeDirectoryData}
      id="active-directory-table"
      dataKey="userId"
      className={styles.table}
      dispatchAction={dispatchAction}
    />
  );
};

export default DataTable;

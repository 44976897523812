import { Modal } from 'components';
import { AttentionIcon } from 'assets/img';
import { notify } from 'utils';
import { useAppDispatch, useAppSelector } from 'store';
import { IRememberMeUser, onChangeRememberMeStatus } from 'store/slices/securityMgmt/remebmerMe';
import { getNotifyMessage } from '../../utils/rememberMeUtils';
import styles from './EnableRememberMeForUserModal.module.scss';

interface Props {
  isOpen: boolean;
  onRequestClose: () => void;
  confirmModalData: IRememberMeUser | IRememberMeUser[];
  selectedUsers: IRememberMeUser[];
  domainId: string;
}

const EnableRememberMeForUserModal = ({ isOpen, onRequestClose, confirmModalData, selectedUsers, domainId }: Props) => {
  const { isLoading } = useAppSelector((state) => state.rememberMe.onChangeRememberMeStatusRequest);
  const dispatch = useAppDispatch();

  const handleConfirmEnableRememberMe = async () => {
    const selectedUserIds = Array.isArray(confirmModalData)
      ? confirmModalData.map((item: IRememberMeUser) => item.userId)
      : [confirmModalData.userId];
    await dispatch(onChangeRememberMeStatus({ domainId, selectedUserIds })).unwrap();
    const message = getNotifyMessage(selectedUsers, confirmModalData);
    notify.success(message);
    onRequestClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={
        <div className={styles.title}>
          <AttentionIcon /> Enable Remember Me?
        </div>
      }
      actionTitle="Enable"
      variantBtn="delete"
      actionFunc={handleConfirmEnableRememberMe}
      actionLoading={isLoading}>
      {selectedUsers.length > 1 ? (
        <>
          At least one of selected users doesn't have MFA enabled. For security reasons TruGrid does not recommend
          enabling <strong>Remember Me</strong> for a User without MFA
        </>
      ) : (
        <>
          For security reasons TruGrid does not recommend enabling <strong>Remember Me</strong> for a User without MFA
        </>
      )}
    </Modal>
  );
};

export default EnableRememberMeForUserModal;

import { KeyIcon } from 'assets/img';
import { DropdownArray } from 'components';
import { notify } from 'utils';
import { useAppDispatch, useAppSelector } from 'store';
import { getWhiteListData } from 'store/slices/securityMgmt/ipWhiteList';
import { DropdownOption } from 'components/DropdownArray/DropdownArray';
import styles from './IPWhitelist.module.scss';

interface Props {
  domainId: string;
}

const IPWhitelist = ({ domainId }: Props) => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);
  const { isLoading } = useAppSelector((state) => state.ipWhiteList.getWhiteListDataRequest);

  const fetchFile = async (value: DropdownOption) => {
    try {
      const { id } = value;
      await dispatch(getWhiteListData({ domainId, displayName: user.displayName, isCsv: id === 'download-csv' }));
      notify.success(
        <>
          <p className={styles.whiteListNotifyTitle}>Preparing file for download ...</p>
          <p className={styles.whiteListNotifyText}>
            Your download will start in a few seconds. If not, please try again
          </p>
        </>,
      );
    } catch (err: any) {
      notify.error(err.message);
    }
  };

  return (
    <div className={styles.whiteList}>
      <div className={styles.whiteListInfo}>
        <div className={styles.whiteListInfoIcon}>
          <KeyIcon />
        </div>
        <div>
          <h4 className={styles.whiteListInfoTitle}>IP Whitelist</h4>
          <p className={styles.whiteListInfoText}>
            Please download the list of TruGrid IP addresses if you must whitelist outbound connections for TruGrid
            software.
          </p>
          <p className={styles.whiteListInfoText}>
            Please note that some of the IP addresses will change occasionally and will require you to conduct another
            download when that happens.
          </p>
        </div>
      </div>
      <DropdownArray
        id="dm-devices-enroll"
        isButton
        isDisabled={isLoading}
        btnTitle={isLoading ? 'Preparing file...' : 'Download'}
        options={[
          { value: 'Download in CSV', id: 'download-csv' },
          { value: 'Download in JSON', id: 'download-json' },
        ]}
        isFilter
        onChange={(value) => fetchFile(value as DropdownOption)}
        className={styles.whiteListDropdown}
        optionsClassName={styles.whiteListDropdownOptions}
        width="11em"
      />
    </div>
  );
};

export default IPWhitelist;

import { Modal } from 'components';
import { useAppDispatch, useAppSelector } from 'store';
import { notify } from 'utils';
import { deleteCustomerDomain } from 'store/slices/customerDomains';

interface Props {
  domainId: string;
  isOpen: boolean;
  onRequestClose: () => void;
}

const DeleteDomainModal = ({ domainId, isOpen, onRequestClose }: Props) => {
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector((state) => state.customerDomains.deleteCustomerDomainRequest);

  const onDeleteDomain = async () => {
    try {
      await dispatch(deleteCustomerDomain(domainId)).unwrap();
    } catch (err: any) {
      notify.error(err.message);
    } finally {
      onRequestClose();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Delete domain"
      actionFunc={onDeleteDomain}
      actionTitle="Delete"
      variantBtn="deleteOutline"
      actionLoading={isLoading}>
      Do you really want to delete this customer domain?
    </Modal>
  );
};

export default DeleteDomainModal;

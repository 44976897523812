import { Modal } from 'components';
import { TABS, SettingTabs } from 'consts';
import { useAppDispatch, useAppSelector } from 'store';
import { resetDomainSettings } from 'store/slices/settings';
import { notify } from 'utils';

interface Props {
  isOpen: boolean;
  onRequestClose: () => void;
  isCustomDomain: boolean;
  setTabsContent: (value: SettingTabs) => void;
}

const ModalResetSingleDomain = ({ isOpen, onRequestClose, isCustomDomain, setTabsContent }: Props) => {
  const dispatch = useAppDispatch();
  const { id } = useAppSelector((state) => state.user.user);
  const { isLoading } = useAppSelector((state) => state.settings.resetDomainSettingsRequest);
  const { currentDomain, settingDomains } = useAppSelector((state) => state.settings);

  const resetSingleSettings = async () => {
    try {
      let applyTo = [id];
      if (isCustomDomain) {
        applyTo = [settingDomains[currentDomain].id];
      }
      await dispatch(resetDomainSettings(applyTo)).unwrap();
      notify.success('Settings reset');
      setTabsContent(TABS);
    } catch (err: any) {
      notify.error(err.message);
    } finally {
      onRequestClose();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Reset settings"
      actionFunc={resetSingleSettings}
      actionTitle="Reset"
      actionLoading={isLoading}
      variantBtn="deleteOutline">
      Do you really want to reset all settings to default?
    </Modal>
  );
};

export default ModalResetSingleDomain;

import { Modal } from 'components';
import { notify } from 'utils';
import { useAppDispatch, useAppSelector } from 'store';
import { uninstallAgents } from 'store/slices/bitLockerMgmt';

interface Props {
  isOpen: boolean;
  onRequestClose: () => void;
  deviceIds: string[];
  domainId: string;
}

const UninstallAgentsModal = ({ isOpen, onRequestClose, deviceIds, domainId }: Props) => {
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector((state) => state.bitLockerMgmt.uninstallAgentsRequest);

  const handleUninstallAgents = async () => {
    try {
      await dispatch(uninstallAgents({ deviceIds, domainId })).unwrap();
      notify.success('Uninstall initiated');
    } catch (err: any) {
      notify.error(err.message);
    } finally {
      onRequestClose();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Uninstall Agents from Devices"
      actionFunc={handleUninstallAgents}
      actionTitle="Uninstall"
      actionLoading={isLoading}
      style={{ content: { width: '39em' } }}>
      <p>
        Do you really want to uninstall <b>{deviceIds.length}</b> {deviceIds.length > 1 ? 'agents' : 'agent'}?
      </p>
    </Modal>
  );
};

export default UninstallAgentsModal;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AsyncThunkConfig, GetThunkAPI } from '@reduxjs/toolkit/dist/createAsyncThunk';
import { AxiosResponse } from 'axios';
import { createExtraReducersForResponses, createHttpRequestInitResult, http } from 'helpers/http';
import { IHttpRequestResult, IPageMeta, IQueryConfig } from 'interfaces';
import { RootState } from 'store/store';
import { queryFormatter } from 'utils';

export interface IShortcutMgmtUser {
  userId: string;
  upn: string;
  displayName: string;
  isDesktopShortcutEnabled: boolean;
  isShortcutMfaRequired: boolean;
  isUserMfaDisabled: boolean;
}

export interface IShortcutMgmtQuery extends IQueryConfig {
  desktopShortcutState: number;
  isShortcutMfaRequired: number | boolean | null;
}

export interface IShortcutMgmtResponse {
  users: IShortcutMgmtUser[];
  isShortcutEnabled: boolean;
  isShortcutMfaRequired: boolean;
  shortcutExpirationDays: number;
}

export interface IShortcutMgmtData {
  data?: IShortcutMgmtUser[];
  isShortcutEnabled?: boolean;
  isShortcutMfaRequired?: boolean;
  shortcutExpirationDays?: number;
  pageMeta?: IPageMeta;
  queryConfig?: IShortcutMgmtQuery;
  isNoData?: boolean;
}

interface IEnableShortcutForDomainPayload {
  domainId: string;
  enableForAllUsers: boolean;
  expirationDays: number;
}

interface IEnableShortcutForUserPayload {
  domainId: string;
  type: 'isDesktopShortcutEnabled' | 'isShortcutMfaRequired';
  selectedUserIds: string[];
}

export const getShortcutMgmtData = createAsyncThunk(
  'shortcutMgmt/getShortcutMgmtData',
  async (
    {
      domainId,
      query,
      _background = false,
    }: {
      domainId: string;
      query?: IShortcutMgmtQuery;
      _background?: boolean;
    },
    thunkAPI,
  ) => {
    const state = thunkAPI.getState() as RootState;
    const queryConfig = state.shortcutMgmt.shortcutMgmtData?.queryConfig || {};
    const queryConfigNew = { ...queryConfig, ...query } as IShortcutMgmtQuery;

    const response: AxiosResponse<IShortcutMgmtResponse> = await http.get(
      queryFormatter(`/api/SecurityManagement/DesktopShortcut/Get?domainId=${domainId}`, queryConfigNew),
    );
    const pageMeta = response?.headers['x-pagination'] ? JSON.parse(response.headers['x-pagination']) : null;

    if (response.status === 204) {
      const data: IShortcutMgmtData = {
        isNoData: true,
      };
      return data;
    }
    const data: IShortcutMgmtData = {
      data: response.data.users,
      isShortcutEnabled: response.data.isShortcutEnabled,
      isShortcutMfaRequired: response.data.isShortcutMfaRequired,
      shortcutExpirationDays: response.data.shortcutExpirationDays,
      pageMeta,
      queryConfig: queryConfigNew,
    };
    return data;
  },
);

export const enableShortcut = createAsyncThunk(
  'shortcutMgmt/enableShortcut',
  async (
    { domainId, enableForAllUsers, expirationDays }: IEnableShortcutForDomainPayload,
    thunkAPI: GetThunkAPI<AsyncThunkConfig>,
  ) => {
    await http.post(
      `/api/SecurityManagement/DesktopShortcut/ChangeDomainStatus?domainId=${domainId}&enableForAllUsers=${enableForAllUsers}&expirationDays=${expirationDays}`,
    );
    await thunkAPI.dispatch(getShortcutMgmtData({ domainId, _background: true }));
  },
);

export const disableShortcut = createAsyncThunk(
  'shortcutMgmt/disableShortcut',
  async ({ domainId }: { domainId: string }) => {
    await http.post(`/api/SecurityManagement/DesktopShortcut/ChangeDomainStatus?domainId=${domainId}`);
  },
);

export const changeMfaRequiredForDomain = createAsyncThunk(
  'shortcutMgmt/changeMfaRequiredForDomain',
  async ({ domainId }: { domainId: string }) => {
    await http.post(`api/SecurityManagement/DesktopShortcut/ChangeMfaRequiredForDmain?domainId=${domainId}`);
  },
);

export const changeShortcutForUser = createAsyncThunk(
  'shortcutMgmt/changeShortcutForUser',
  async (
    { domainId, type, selectedUserIds }: IEnableShortcutForUserPayload,
    thunkAPI: GetThunkAPI<AsyncThunkConfig>,
  ) => {
    const url =
      type === 'isDesktopShortcutEnabled'
        ? '/api/SecurityManagement/DesktopShortcut/ChangeUserStatus'
        : '/api/SecurityManagement/DesktopShortcut/SwitchMfaRequiredForUsers';
    await http.post(url, selectedUserIds);
    const state: RootState = thunkAPI.getState() as RootState;
    const query = state.shortcutMgmt.shortcutMgmtData.queryConfig;
    await thunkAPI.dispatch(getShortcutMgmtData({ domainId, query, _background: true }));
  },
);

export const toggleMfaRequiredForUser = createAsyncThunk(
  'shortcutMgmt/toggleMfaRequiredForUser',
  async (
    { domainId, selectedUserIds }: { domainId: string; selectedUserIds: string[] },
    thunkAPI: GetThunkAPI<AsyncThunkConfig>,
  ) => {
    await http.post('/api/SecurityManagement/DesktopShortcut/SwitchMfaRequiredForUsers', selectedUserIds);
    const state: RootState = thunkAPI.getState() as RootState;
    const query = state.shortcutMgmt.shortcutMgmtData.queryConfig;
    await thunkAPI.dispatch(getShortcutMgmtData({ domainId, query, _background: true }));
  },
);

interface IShortcutMgmtState {
  shortcutMgmtData: IShortcutMgmtData;
  getShortcutMgmtDataRequest: IHttpRequestResult<IShortcutMgmtData>;
  enableShortcutRequest: IHttpRequestResult<void>;
  disableShortcutRequest: IHttpRequestResult<void>;
  changeShortcutForUserRequest: IHttpRequestResult<void>;
  changeMfaRequiredForDomainRequest: IHttpRequestResult<void>;
  toggleMfaRequiredForUserRequest: IHttpRequestResult<void>;
}

const initialState: IShortcutMgmtState = {
  shortcutMgmtData: {},
  getShortcutMgmtDataRequest: createHttpRequestInitResult(),
  enableShortcutRequest: createHttpRequestInitResult(),
  disableShortcutRequest: createHttpRequestInitResult(),
  changeShortcutForUserRequest: createHttpRequestInitResult(),
  changeMfaRequiredForDomainRequest: createHttpRequestInitResult(),
  toggleMfaRequiredForUserRequest: createHttpRequestInitResult(),
};

export const shortcutMgmtSlice = createSlice({
  name: 'ShortcutMgmt',
  initialState,
  reducers: {
    updateShortcutMgmtData: (state, { payload }) => {
      state.shortcutMgmtData = payload;
    },
  },
  extraReducers: (builder) => {
    createExtraReducersForResponses<IShortcutMgmtState>(
      builder,
      getShortcutMgmtData,
      'getShortcutMgmtDataRequest',
      (state, { payload }) => {
        state.shortcutMgmtData = payload;
      },
    );
    createExtraReducersForResponses<IShortcutMgmtState>(builder, enableShortcut, 'enableShortcutRequest');
    createExtraReducersForResponses<IShortcutMgmtState>(builder, disableShortcut, 'disableShortcutRequest', (state) => {
      state.shortcutMgmtData = {};
    });
    createExtraReducersForResponses<IShortcutMgmtState>(builder, changeShortcutForUser, 'changeShortcutForUserRequest');
    createExtraReducersForResponses<IShortcutMgmtState>(
      builder,
      changeMfaRequiredForDomain,
      'changeMfaRequiredForDomainRequest',
      (state) => {
        state.shortcutMgmtData = {
          ...state.shortcutMgmtData,
          isShortcutMfaRequired: !state.shortcutMgmtData?.isShortcutMfaRequired,
          data: state.shortcutMgmtData.data?.map((item) => ({
            ...item,
            isShortcutMfaRequired: item.isDesktopShortcutEnabled
              ? !state.shortcutMgmtData?.isShortcutMfaRequired
              : item.isShortcutMfaRequired,
          })),
        };
      },
    );
    createExtraReducersForResponses<IShortcutMgmtState>(
      builder,
      toggleMfaRequiredForUser,
      'toggleMfaRequiredForUserRequest',
    );
  },
});

export const { updateShortcutMgmtData } = shortcutMgmtSlice.actions;
export default shortcutMgmtSlice.reducer;

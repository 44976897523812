import cn from 'classnames';
import { Location } from 'history';
import { CustomersIcon } from 'assets/img';
import Link from 'components/Link/Link';
import { spAdmin, spTechAdmin, techAdmin } from 'consts';
import styles from '../NavbarItem.module.scss';
import { useAppSelector } from 'store';
import { useLocation } from 'react-router-dom';

interface Props {
  role: string;
  domainStatus: number;
  onNotCompanyClick: () => void;
  blockLeftMenu?: boolean;
}

const CustomerItems = ({ role, domainStatus, onNotCompanyClick, blockLeftMenu }: Props) => {
  const location = useLocation<Location>();
  const { selectedDomain: selectedCustDomain } = useAppSelector((state) => state.customerDomains);
  const { branding } = useAppSelector((state) => state.user);
  const { useInvertedColorScheme: isInvertedColors } = branding || {};
  const color = isInvertedColors ? '#000' : '#fff';

  const custDomainSelected =
    location.pathname.includes('customer-management') && location.pathname.split('/').length > 2
      ? location.pathname.split('/')[2]
      : '';

  let customersPages = [
    {
      label: 'Customer Workspace',
      link: `/customer-management/${custDomainSelected}/customer-workspace`,
    },
    {
      label: 'Resource Assignment',
      link: `/customer-management/${custDomainSelected}/resource-assignments`,
    },
    {
      label: 'BitLocker Management',
      link: `/customer-management/${custDomainSelected}/device-management`,
    },
    {
      label: 'Roles Management',
      link: `/customer-management/${custDomainSelected}/roles-management`,
    },
    {
      label: 'Security Management',
      link: `/customer-management/${custDomainSelected}/security-management`,
    },
    {
      label: 'Domain Management',
      link: `/customer-management/${custDomainSelected}/domain-management`,
    },
    {
      label: 'Reports',
      link: `/customer-management/${custDomainSelected}/reports`,
    },
  ];
  if ([techAdmin].includes(role)) {
    customersPages = customersPages.filter((i) => !['Domain Management'].includes(i.label));
  }
  if ([spTechAdmin, techAdmin].includes(role)) {
    customersPages = customersPages.filter((i) => i.label !== 'Roles Management');
  }
  if (![spAdmin, spTechAdmin, techAdmin].includes(role) || selectedCustDomain?.status !== 2) {
    customersPages = customersPages.filter((i) => i.label !== 'Customer Workspace');
  }
  if (selectedCustDomain?.status !== 2) {
    customersPages = customersPages.filter((i) => i.label !== 'Security Management');
  }

  return (
    <>
      <li
        className={cn(styles.item, {
          [styles.active]: location.pathname.includes('customer-management') && !custDomainSelected,
          [styles.itemDisabled]: !domainStatus || blockLeftMenu,
          [styles.branding]: branding && !isInvertedColors,
          [styles.inverted]: isInvertedColors,
        })}
        data-tip="Select and manage customer domains and resources"
        data-iscapture="true"
        onClick={onNotCompanyClick}>
        <span style={branding?.backGroundColor ? { background: color } : {}} />
        <Link to="/customer-management" id="customer-management" className={styles.itemLink}>
          <>
            <CustomersIcon />
            <span>Customers</span>
          </>
        </Link>
      </li>
      {custDomainSelected && (
        <ul className={styles.itemSublist} style={{ marginTop: '-1em' }}>
          <span className={cn(styles.itemSublistDomainName, { [styles.inverted]: isInvertedColors })}>
            {custDomainSelected}
          </span>
          {customersPages.map((item) => (
            <li
              key={item.label}
              className={cn(styles.item, {
                [styles.active]: location.pathname.includes('customer-management') && location.pathname === item.link,
                [styles.branding]: branding && !isInvertedColors,
                [styles.inverted]: isInvertedColors,
              })}>
              <span style={branding?.backGroundColor ? { background: color } : {}} />
              <Link
                to={item.link}
                id={`customer-${item.label.split(' ').join('-').toLowerCase()}`}
                className={cn(styles.itemLink, styles.itemSublistLink)}>
                {item.label}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

export default CustomerItems;

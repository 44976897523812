import { Loader } from 'components';
import ToggleEnableModal from './components/ToggleEnableModal/ToggleEnableModal';
import UpdateSentryAlert from './components/UpdateSentryAlert/UpdateSentryAlert';
import HeaderPassMgmt from './components/HeaderPassMgmt/HeaderPassMgmt';
import LinkItem from './components/LinkItem/LinkItem';
import { useAppSelector } from 'store';
import useModal from 'hooks/useModal';
import styles from './SecurityMgmtPass.module.scss';

interface Props {
  domainId: string;
}

const SecurityMgmtPass = ({ domainId }: Props) => {
  const { isLoading } = useAppSelector((state) => state.passMgmt.getPassMgmtDataRequest);
  const { isLoading: isButtonLoading } = useAppSelector((state) => state.passMgmt.togglePassMgmtStatusRequest);

  const { passMgmtState } = useAppSelector((state) => state.passMgmt);
  const { isOpen: showToggleModal, openModal: openToggleModal, closeModal: closeToggleModal } = useModal();

  if (isLoading) {
    return <Loader id="loading-password-mgmt" />;
  }

  return (
    <>
      <div className={styles.passMgmt}>
        {passMgmtState?.shouldUpdateSentry && <UpdateSentryAlert />}
        <HeaderPassMgmt
          isEnabled={passMgmtState?.isEnabled || false}
          shouldUpdateSentry={passMgmtState?.shouldUpdateSentry || false}
          openToggleModal={openToggleModal}
        />
        {passMgmtState!.isEnabled && <LinkItem passMgmtState={passMgmtState!} />}
      </div>
      {showToggleModal && (
        <ToggleEnableModal
          domainId={domainId}
          isEnabled={passMgmtState!.isEnabled}
          showModal={showToggleModal}
          closeToggleModal={closeToggleModal}
          isLoading={isButtonLoading}
        />
      )}
    </>
  );
};

export default SecurityMgmtPass;

import { SwitchCheckbox, Table } from 'components';
import { TooltipIcon } from 'assets/img';
import EmptyTable from '../EmptyTable/EmptyTable';
import {
  getShortcutMgmtData,
  IShortcutMgmtData,
  IShortcutMgmtQuery,
  IShortcutMgmtUser,
} from 'store/slices/securityMgmt/shortcutMgmt';
import { ChangeShortcutUserType } from '../../ShortcutMgmt';
import { useAppDispatch } from 'store';
import { IQueryConfig } from 'interfaces';
import styles from './ShortcutMgmtTable.module.scss';

interface Props {
  domainId: string;
  shortcutData: IShortcutMgmtData;
  selectedUsers: IShortcutMgmtUser[];
  setSelectedUsers: (selectedUsers: IShortcutMgmtUser[]) => void;
  btnLoadingCurrent: string[];
  onChangeShortcutForUser: (
    isHeaderBtn: boolean,
    user: IShortcutMgmtUser,
    type: ChangeShortcutUserType,
    actionType?: string,
    extra?: boolean,
  ) => Promise<void>;
  isDomainOnPremise: boolean;
}

const ShortcutMgmtTable = ({
  domainId,
  shortcutData,
  selectedUsers,
  setSelectedUsers,
  btnLoadingCurrent,
  onChangeShortcutForUser,
  isDomainOnPremise,
}: Props) => {
  const dispatch = useAppDispatch();

  const { desktopShortcutState } = shortcutData?.queryConfig || {};

  const columns = [
    {
      checkbox: !!(!isDomainOnPremise || desktopShortcutState),
      name: 'User',
      data: ({ displayName, upn }: { displayName: string; upn: string }) => (
        <div className={styles.tableWrap}>
          <div className={styles.tableName}>{displayName || 'N/A'}</div>
          <div className={styles.tableUpn}>{upn}</div>
        </div>
      ),
      sortable: 'displayName',
      search: 'Name',
      width: '40%',
    },
    {
      name: 'Desktop Shortcut',
      data: (user: IShortcutMgmtUser) => (
        <div className={styles.tableStatus}>
          <SwitchCheckbox
            id={user.userId}
            name={user.userId}
            onChange={() => onChangeShortcutForUser(false, user, 'isDesktopShortcutEnabled')}
            checked={user.isDesktopShortcutEnabled}
            disabled={selectedUsers.length > 0 || !!btnLoadingCurrent.includes(user.userId)}
          />
          {user.isDesktopShortcutEnabled ? (
            <span className={styles.tableStatusEnabled}>ON</span>
          ) : (
            <span className={styles.tableStatusDisabled}>OFF</span>
          )}
        </div>
      ),
      sortable: 'isDesktopShortcutEnabled',
      width: '30%',
    },
    {
      name: 'MFA Code Required',
      data: (user: IShortcutMgmtUser) => (
        <div className={styles.tableStatus}>
          <SwitchCheckbox
            id={user.userId}
            name={user.userId}
            onChange={() => onChangeShortcutForUser(false, user, 'isShortcutMfaRequired')}
            checked={user.isShortcutMfaRequired}
            disabled={
              selectedUsers.length > 0 || !!btnLoadingCurrent.includes(user.userId) || !user.isDesktopShortcutEnabled
            }
          />
          {user.isShortcutMfaRequired ? (
            <span className={styles.tableStatusEnabled}>ON</span>
          ) : (
            <span className={styles.tableStatusDisabled}>OFF</span>
          )}
        </div>
      ),
      sortable: 'isShortcutMfaRequired',
      width: '30%',
      disabled: !isDomainOnPremise,
      extraContent: (
        <span
          className={styles.tooltip}
          data-tip="Secure Desktop Shortcuts by enabling MFA Code"
          data-for="info-tooltip">
          <TooltipIcon />
        </span>
      ),
    },
  ];

  const dispatchAction = async (query: IQueryConfig) => {
    await dispatch(
      getShortcutMgmtData({ domainId: domainId, query: query as IShortcutMgmtQuery, _background: true }),
    ).unwrap();
  };

  return (
    <Table<IShortcutMgmtUser>
      data={shortcutData}
      noData={<EmptyTable />}
      selectedItems={selectedUsers}
      setSelectedItems={setSelectedUsers}
      columns={columns}
      id="shrotcut-mgmt-table"
      dataKey="userId"
      dispatchAction={dispatchAction}
      className={styles.table}
    />
  );
};

export default ShortcutMgmtTable;

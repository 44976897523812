import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AsyncThunkConfig, GetThunkAPI } from '@reduxjs/toolkit/dist/createAsyncThunk';
import { AxiosResponse } from 'axios';
import { createExtraReducersForResponses, createHttpRequestInitResult, http } from 'helpers/http';
import { IHttpRequestResult, IPageMeta, IQueryConfig } from 'interfaces';
import { RootState } from 'store/store';
import { queryFormatter } from 'utils';

export interface IRememberMeUser {
  userId: string;
  isMfaEnabled: boolean;
  isRememberMeEnabled: boolean;
  upn: string;
  displayName: string;
}

export interface IRememberMeResponse {
  users: IRememberMeUser[];
  isRememberMeEnabled: boolean;
}

export interface IRememberMeData {
  data?: IRememberMeUser[];
  isRememberMeEnabled?: boolean;
  pageMeta?: IPageMeta;
  queryConfig?: IQueryConfig;
  isNoData?: boolean;
}

export interface IGetRememberMeDataPayload {
  domainId: string;
  query?: IQueryConfig;
  _background?: boolean;
}

export interface IToggleRememberMePayload {
  domainId: string;
  isRememberMeEnabled: boolean;
}

export interface IChangeRememberMeStatusPayload {
  domainId: string;
  selectedUserIds: string[];
}

export const getRememberMeData = createAsyncThunk(
  'rememberMe/getRememberMeData',
  async ({ domainId, query, _background }: IGetRememberMeDataPayload, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const queryConfig = state.rememberMe.rememberMeData?.queryConfig || {};
    const queryConfigNew = { ...queryConfig, ...query };

    const response: AxiosResponse<IRememberMeResponse> = await http.get(
      queryFormatter(`/api/SecurityManagement/RememberMe/Get?domainId=${domainId}`, queryConfigNew),
    );
    const pageMeta = response?.headers['x-pagination'] ? JSON.parse(response.headers['x-pagination']) : null;
    if (response.status === 204) {
      const data: IRememberMeData = {
        isNoData: true,
      };
      return data;
    } else {
      const data: IRememberMeData = {
        data: response.data.users,
        isRememberMeEnabled: response.data.isRememberMeEnabled,
        pageMeta,
        queryConfig: queryConfigNew,
      };
      return data;
    }
  },
);

export const onToggleRememberMe = createAsyncThunk(
  'rememberMe/onEnableRememberMe',
  async ({ domainId, isRememberMeEnabled }: IToggleRememberMePayload, thunkAPI: GetThunkAPI<AsyncThunkConfig>) => {
    await http.post('/api/SecurityManagement/RememberMe/ChangeDomainStatus', domainId);
    if (!isRememberMeEnabled) {
      await thunkAPI.dispatch(getRememberMeData({ domainId }));
    }
  },
);

export const onChangeRememberMeStatus = createAsyncThunk(
  'rememberMe/onChangeRememberMeStatus',
  async ({ domainId, selectedUserIds }: IChangeRememberMeStatusPayload, thunkAPI: GetThunkAPI<AsyncThunkConfig>) => {
    await http.post('/api/SecurityManagement/RememberMe/ChangeUsersStatus', selectedUserIds);
    const query = { pageNumber: 1 };
    await thunkAPI.dispatch(getRememberMeData({ domainId, query, _background: true }));
  },
);

export interface IRememberMeState {
  rememberMeData: IRememberMeData | null;
  getRememberMeDataRequest: IHttpRequestResult<IRememberMeData>;
  onToggleRememberMeRequest: IHttpRequestResult<any>;
  onChangeRememberMeStatusRequest: IHttpRequestResult<any>;
}

const initialState: IRememberMeState = {
  rememberMeData: null,
  getRememberMeDataRequest: createHttpRequestInitResult(),
  onToggleRememberMeRequest: createHttpRequestInitResult(),
  onChangeRememberMeStatusRequest: createHttpRequestInitResult(),
};

export const rememberMeSlice = createSlice({
  name: 'RememberMe',
  initialState,
  reducers: {
    updateRememberMeData: (state, { payload }) => {
      state.rememberMeData = payload;
    },
  },
  extraReducers: (builder) => {
    createExtraReducersForResponses<IRememberMeState>(
      builder,
      getRememberMeData,
      'getRememberMeDataRequest',
      (state, { payload }) => {
        state.rememberMeData = payload;
      },
    );
    createExtraReducersForResponses<IRememberMeState>(
      builder,
      onToggleRememberMe,
      'onToggleRememberMeRequest',
      (state, action) => {
        const { isRememberMeEnabled } = action.meta.arg;
        if (isRememberMeEnabled) {
          state.rememberMeData = null;
        }
      },
    );
    createExtraReducersForResponses<IRememberMeState>(
      builder,
      onChangeRememberMeStatus,
      'onChangeRememberMeStatusRequest',
    );
  },
});

export const { updateRememberMeData } = rememberMeSlice.actions;

export default rememberMeSlice.reducer;

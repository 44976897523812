/* eslint-disable prefer-const */
import { useState, useEffect } from 'react';
import { Loader } from 'components';
import { notify } from 'utils';
import { getDomainADStatus } from 'helpers';
import ReactTooltip from 'react-tooltip';
import ToggleEnableModal from './components/ToggleEnableModal/ToggleEnableModal';
import NoUsers from './components/NoUsers/NoUsers';
import HeaderActions from './components/HeaderActions/HeaderActions';
import ShortcutMgmtStatus from './components/ShortcutMgmtStatus/ShortcutMgmtStatus';
import ShortcutMgmtTable from './components/ShortcutMgmtTable/ShortcutMgmtTable';
import ShortcutMgmtHeader from './components/ShortcutMgmtHeader/ShortcutMgmtHeader';
import ShortcutMgmtTitle from './components/ShortcutMgmtTitle/ShortcutMgmtTitle';
import { checkActionType } from './utils/shortcutUtils';
import ToggleMfaRequiredModal from './components/ToggleMfaRequiredModal/ToggleMfaRequiredModal';
import ShortcutFilters from './components/ShortcutFilters/ShortcutFilters';
import DisableMFACodeModal from './components/DisableMFACodeModal/DisableMFACodeModal';
import EnableMFACodeModal from './components/EnableMFACodeModal/EnableMFACodeModal';
import { IDomain } from 'interfaces';
import { useAppDispatch, useAppSelector } from 'store';
import {
  IShortcutMgmtUser,
  changeShortcutForUser,
  getShortcutMgmtData,
  toggleMfaRequiredForUser,
} from 'store/slices/securityMgmt/shortcutMgmt';
import styles from './ShortcutMgmt.module.scss';

export type ChangeShortcutUserType = 'isDesktopShortcutEnabled' | 'isShortcutMfaRequired';

interface Props {
  domain: IDomain;
  isCompanyPage: boolean;
}

const ShortcutMgmt = ({ domain, isCompanyPage }: Props) => {
  const dispatch = useAppDispatch();
  const { shortcutMgmtData: shortcutData } = useAppSelector((state) => state.shortcutMgmt);
  let { desktopShortcutState, isShortcutMfaRequired: shortcutMfaRequired } = shortcutData?.queryConfig || {};
  if (shortcutMfaRequired === null) {
    shortcutMfaRequired = true;
  }
  shortcutMfaRequired = shortcutMfaRequired ? 0 : 1;

  const { isLoading } = useAppSelector((state) => state.shortcutMgmt.getShortcutMgmtDataRequest);
  const isShortcutEnabled = shortcutData?.isShortcutEnabled;
  const isNoUsers = shortcutData?.isNoData;

  const [btnLoadingCurrent, setBtnLoadingCurrent] = useState<string[]>([]);
  const [isHeaderBtnLoading, setIsHeaderBtnLoading] = useState<boolean>(false);

  const [selectedUsers, setSelectedUsers] = useState<IShortcutMgmtUser[]>([]);

  const [isShowToggleEnableModal, setIsShowToggleEnableModal] = useState<boolean>(false);
  const toggleShowEnableModal = () => setIsShowToggleEnableModal(!isShowToggleEnableModal);

  const [isShowToggleMfaRequiredModal, setIsShowToggleMfaRequiredModal] = useState<boolean>(false);
  const toggleShowToggleMfaRequiredModal = () => setIsShowToggleMfaRequiredModal(!isShowToggleMfaRequiredModal);

  const [selectedUser, setSelectedUser] = useState<IShortcutMgmtUser[] | IShortcutMgmtUser | null>(null);
  const [isShowDisableMFACodeModal, setIsShowDisableMFACodeModal] = useState<boolean>(false);
  const toggleShowDisableMFACodeModal = () => setIsShowDisableMFACodeModal(!isShowDisableMFACodeModal);

  const [isShowEnableMFACodeModal, setIsShowEnableMFACodeModal] = useState<boolean>(false);
  const toggleShowEnableMFACodeModal = () => setIsShowEnableMFACodeModal(!isShowEnableMFACodeModal);

  const { isDomainOnPremise } = getDomainADStatus(domain);

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  useEffect(() => {
    const fetchData = async () => {
      const queryConfig = {
        pageNumber: 1,
        pageSize: 10,
        searchQuery: '',
        orderBy: 'displayName asc',
        isShortcutMfaRequired: null,
        desktopShortcutState: 0,
      };
      try {
        await dispatch(getShortcutMgmtData({ domainId: domain.id, query: queryConfig })).unwrap();
      } catch (err: any) {
        notify.error(err.message);
      }
    };
    fetchData();
  }, []);

  const onChangeShortcutForUser = async (
    isHeaderBtn: boolean,
    user: IShortcutMgmtUser,
    type: ChangeShortcutUserType,
    actionType?: string,
    extra: boolean = false,
  ) => {
    try {
      if (isHeaderBtn) {
        if (actionType === 'enabledAll' && type === 'isShortcutMfaRequired') {
          setSelectedUser(selectedUsers);
          return toggleShowDisableMFACodeModal();
        }
        const isLeastOneUserHasMfaDisabled = selectedUsers.some((item) => item.isUserMfaDisabled);
        if (isLeastOneUserHasMfaDisabled && type === 'isShortcutMfaRequired') {
          setSelectedUser(selectedUsers);
          return toggleShowEnableMFACodeModal();
        }
        const selectedUserIds = selectedUsers.map((item) => item.userId);
        setIsHeaderBtnLoading(true);
        await dispatch(changeShortcutForUser({ domainId: domain.id, type, selectedUserIds })).unwrap();
        if (selectedUsers.length) setSelectedUsers([]);
        const notifyText = checkActionType(selectedUsers, user, extra ? 'isDesktopShortcutEnabled' : type);
        notify.success(notifyText);
        setIsHeaderBtnLoading(false);
      }
      if (!isHeaderBtn) {
        if (user.isDesktopShortcutEnabled && user.isShortcutMfaRequired && type === 'isShortcutMfaRequired') {
          setSelectedUser(user);
          return toggleShowDisableMFACodeModal();
        }
        if (user.isUserMfaDisabled && type === 'isShortcutMfaRequired') {
          setSelectedUser(user);
          return toggleShowEnableMFACodeModal();
        }
        setBtnLoadingCurrent((itemCurrent) => [...itemCurrent, user.userId]);
        await dispatch(changeShortcutForUser({ domainId: domain.id, type, selectedUserIds: [user.userId] })).unwrap();
        if (selectedUsers.length) setSelectedUsers([]);
        const notifyText = checkActionType(selectedUsers, user, type);
        notify.success(notifyText);
        setBtnLoadingCurrent((item) => item.filter((i) => i !== user.userId));
      }
    } catch (err: any) {
      notify.error(err.message);
    }
  };

  const onToggleMfaRequiredForUser = async (massUpdate: boolean, typeAction: string) => {
    try {
      if (massUpdate) {
        setIsHeaderBtnLoading(true);
        const selectedUserIds = selectedUsers.map((item) => item.userId);
        const isEnableShortcutWithMfa = selectedUsers.every((i) => !i.isDesktopShortcutEnabled);
        await dispatch(toggleMfaRequiredForUser({ domainId: domain.id, selectedUserIds })).unwrap();
        if (selectedUsers.length) setSelectedUsers([]);
        setBtnLoadingCurrent((item) => item.filter((i) => i !== (selectedUser as IShortcutMgmtUser).userId));
        const notifyText = checkActionType(
          selectedUsers,
          selectedUser as IShortcutMgmtUser,
          isEnableShortcutWithMfa ? 'isDesktopShortcutEnabled' : 'isShortcutMfaRequired',
        );
        notify.success(notifyText);
        setIsHeaderBtnLoading(false);
      }
      if (!massUpdate) {
        const selectedUserIds = [(selectedUser as IShortcutMgmtUser).userId];
        await dispatch(toggleMfaRequiredForUser({ domainId: domain.id, selectedUserIds })).unwrap();
        const notifyText = checkActionType(selectedUsers, selectedUser as IShortcutMgmtUser, 'isShortcutMfaRequired');
        notify.success(notifyText);
      }
    } catch (err: any) {
      notify.error(err.message);
    } finally {
      if (typeAction === 'disable') {
        toggleShowDisableMFACodeModal();
      }
      if (typeAction === 'enable') {
        toggleShowEnableMFACodeModal();
      }
      setSelectedUser(null);
    }
  };

  return (
    <div className={styles.shortcut}>
      <ShortcutMgmtTitle isCompanyPage={isCompanyPage} domain={domain} />
      {isLoading ? (
        <Loader id="loading-shortcut-mgmt" />
      ) : isNoUsers ? (
        <NoUsers />
      ) : (
        <>
          <ShortcutMgmtStatus
            shortcutData={shortcutData}
            toggleShowEnableModal={toggleShowEnableModal}
            toggleShowToggleMfaRequiredModal={toggleShowToggleMfaRequiredModal}
            isDomainOnPremise={isDomainOnPremise}
            selectedUsers={selectedUsers}
          />
          {selectedUsers.length ? (
            <HeaderActions
              shortcutData={shortcutData}
              selectedUsers={selectedUsers}
              setSelectedUsers={setSelectedUsers}
              onChangeShortcutForUser={onChangeShortcutForUser}
              isHeaderBtnLoading={isHeaderBtnLoading}
              isDomainOnPremise={isDomainOnPremise}
            />
          ) : (
            isShortcutEnabled && (
              <ShortcutFilters
                id="shortcut-filters"
                isDomainOnPremise={isDomainOnPremise}
                desktopShortcutState={desktopShortcutState as number}
                shortcutMfaRequired={shortcutMfaRequired}
                domainId={domain.id}
              />
            )
          )}
          {isShortcutEnabled ? (
            <ShortcutMgmtTable
              domainId={domain.id}
              shortcutData={shortcutData}
              selectedUsers={selectedUsers}
              setSelectedUsers={setSelectedUsers}
              btnLoadingCurrent={btnLoadingCurrent}
              onChangeShortcutForUser={onChangeShortcutForUser}
              isDomainOnPremise={isDomainOnPremise}
            />
          ) : (
            <ShortcutMgmtHeader />
          )}

          {isShowToggleEnableModal && (
            <ToggleEnableModal
              isOpen={isShowToggleEnableModal}
              onRequestClose={toggleShowEnableModal}
              domainId={domain.id}
            />
          )}

          {isShowToggleMfaRequiredModal && (
            <ToggleMfaRequiredModal
              isOpen={isShowToggleMfaRequiredModal}
              onRequestClose={toggleShowToggleMfaRequiredModal}
              domainId={domain.id}
            />
          )}

          {isShowDisableMFACodeModal && (
            <DisableMFACodeModal
              isOpen={isShowDisableMFACodeModal}
              onRequestClose={toggleShowDisableMFACodeModal}
              onToggleMfaRequiredForUser={onToggleMfaRequiredForUser}
              user={selectedUser as IShortcutMgmtUser}
            />
          )}

          {isShowEnableMFACodeModal && (
            <EnableMFACodeModal
              isOpen={isShowEnableMFACodeModal}
              onRequestClose={toggleShowEnableMFACodeModal}
              onToggleMfaRequiredForUser={onToggleMfaRequiredForUser}
              user={selectedUser as IShortcutMgmtUser}
            />
          )}
        </>
      )}
      <ReactTooltip
        className={styles.infoTooltip}
        id="info-tooltip"
        type="light"
        place="bottom"
        offset={{ top: 0, left: 120 }}
        effect="solid"
      />
    </div>
  );
};

export default ShortcutMgmt;

import { useState, useEffect } from 'react';
import { StatusMessage } from 'components';
import ReactTooltip from 'react-tooltip';
import DevicesSearchFilter from '../../components/SearchFilter/SearchFilter';
import DeleteDevicesModal from './components/DeleteDevicesModal/DeleteDevicesModal';
import UninstallAgentsModal from './components/UninstallAgentsModal/UninstallAgentsModal';
import DevicesBulkActions from './components/DevicesBulkActions/DevicesBulkActions';
import { useAppSelector } from 'store';
import { checkIsCustTechAdmin, checkIsVisibleNoLicensesMsg } from 'pages/BitLockerMgmt/utils/bitLockerUtils';
import NoLicensesMessage from 'pages/BitLockerMgmt/components/NoLicensesMessage/NoLicensesMessage';
import useModal from 'hooks/useModal';
import DevicesTable from './components/DevicesTable/DevicesTable';
import { IBitlockerDevice } from 'store/slices/bitLockerMgmt';
import styles from './Devices.module.scss';
import { IAxiosError } from 'helpers/http';

interface Props {
  domainId: string;
  userRole: string;
}

const Devices = ({ domainId, userRole }: Props) => {
  const { bitlockerDevicesData: devices } = useAppSelector((state) => state.bitLockerMgmt);
  const { bitlockerSubscriptionStatus } = useAppSelector((state) => state.bitLockerMgmt);
  const { error, isError } = useAppSelector((state) => state.bitLockerMgmt.getBitlockerDevicesRequest);

  const [selectedDeviceIds, setSelectedDeviceIds] = useState<IBitlockerDevice[]>([]);

  const {
    isOpen: isOpenDevicesModal,
    openModal: openDevicesModal,
    closeModal: closeDevicesModal,
    modalData: selectedDeviceIdsModal,
  } = useModal<string[]>();
  const {
    isOpen: isOpenUninstallAgentsModal,
    openModal: openUninstallAgentsModal,
    closeModal: closeUninstallAgentsModal,
    modalData: selectedAgentIdsModal,
  } = useModal<string[]>();

  if (isError) {
    return <StatusMessage error>{(error as IAxiosError).message}</StatusMessage>;
  }

  const handleOpenDevicesModal = (device?: IBitlockerDevice) => {
    if (device) {
      openDevicesModal([device.deviceId]);
    } else {
      openDevicesModal(selectedDeviceIds.map((device) => device.deviceId));
    }
  };

  const handleOpenAgentsModal = (device?: IBitlockerDevice) => {
    if (device) {
      openUninstallAgentsModal([device.deviceId]);
    } else {
      openUninstallAgentsModal(selectedDeviceIds.map((device) => device.deviceId));
    }
  };

  const devicesWithoutLicenses = devices?.pageMeta.DevicesWithoutLicenses;

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const isCustTechAdmin = checkIsCustTechAdmin(userRole);
  const isVisibleNoLicensesMsg = checkIsVisibleNoLicensesMsg(userRole);

  return (
    <>
      <div className={styles.devices}>
        {!bitlockerSubscriptionStatus && <div className={styles.devicesTabBackdrop} />}
        {!!devicesWithoutLicenses && isVisibleNoLicensesMsg && (
          <NoLicensesMessage devicesWithoutLicenses={devicesWithoutLicenses} isCustTechAdmin={isCustTechAdmin} />
        )}
        {selectedDeviceIds.length ? (
          <DevicesBulkActions
            selectedDeviceIds={selectedDeviceIds}
            setSelectedDeviceIds={setSelectedDeviceIds}
            openUninstallAgentsModal={handleOpenAgentsModal}
            openDevicesModal={handleOpenDevicesModal}
          />
        ) : (
          devices && <DevicesSearchFilter devices={devices} domainId={domainId} />
        )}
        <DevicesTable
          domainId={domainId}
          selectedDeviceIds={selectedDeviceIds}
          setSelectedDeviceIds={setSelectedDeviceIds}
          openDevicesModal={handleOpenDevicesModal}
          openUninstallAgentsModal={handleOpenAgentsModal}
        />
      </div>
      {isOpenDevicesModal && selectedDeviceIdsModal && (
        <DeleteDevicesModal
          isOpen={isOpenDevicesModal}
          onRequestClose={closeDevicesModal}
          deviceIds={selectedDeviceIdsModal}
          domainId={domainId}
        />
      )}
      {isOpenUninstallAgentsModal && selectedAgentIdsModal && (
        <UninstallAgentsModal
          isOpen={isOpenUninstallAgentsModal}
          onRequestClose={closeUninstallAgentsModal}
          deviceIds={selectedAgentIdsModal}
          domainId={domainId}
        />
      )}
    </>
  );
};

export default Devices;

/* eslint-disable @typescript-eslint/no-unsafe-return */
import { useState } from 'react';
import cn from 'classnames';
import { ArrowDownIcon, ArrowUpIcon, LoaderIcon } from 'assets/img';
import Checkbox from 'components/Checkbox/Checkbox';
import { notify, truncateString } from 'utils';
import SearchInput from 'components/SearchInput/SearchInput';
import Pagination from 'components/Pagination/Pagination';
import ReactTooltip from 'react-tooltip';
import { IPageMeta, IQueryConfig } from 'interfaces';
import styles from './Table.module.scss';

interface Column {
  name: string;
  nameFilter?: any;
  sortable?: string;
  search?: string;
  searchNotDebounce?: (value: string) => void;
  checkbox?: boolean | ((item: any) => boolean);
  checkboxDisabled?: (item: any) => boolean;
  tooltip?: {
    tip: string;
    id: string;
  };
  data?: any;
  width: string | ((item: any) => string);
  disabled?: boolean;
  extraContent?: JSX.Element;
}
interface TableProps<T> {
  columns: Column[];
  data: {
    data?: T[];
    queryConfig?: IQueryConfig | null;
    pageMeta?: IPageMeta | null;
  } | null;
  selectedItems?: T[];
  setSelectedItems?: (selectedItems: T[]) => void;
  ruleForSelectItems?: (item: T) => boolean;
  ruleForToggleAll?: string[];
  id?: string;
  isLoading?: boolean;
  dataKey: string;
  noData?: boolean | JSX.Element | undefined;
  noDataOnlyBody?: boolean;
  pagination?: boolean;
  onClickRow?: (item: T) => void;
  className?: string;
  classNameRow?: string | ((item: T) => string);
  onMouseEnter?: (e: MouseEvent, item: T) => void;
  onMouseLeave?: (e: MouseEvent, item: T) => void;
  rowTip?: (item: T) => string | null;
  dispatchAction?: (query: IQueryConfig) => Promise<void>;
}

const Table = <T,>({
  columns = [],
  data,
  selectedItems = [],
  setSelectedItems,
  ruleForSelectItems,
  ruleForToggleAll,
  id,
  isLoading,
  dataKey,
  noData,
  noDataOnlyBody,
  pagination = true,
  onClickRow,
  className,
  classNameRow,
  onMouseEnter,
  onMouseLeave,
  rowTip,
  dispatchAction,
}: TableProps<T>) => {
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [sortingCurrent, setSortingCurrent] = useState('');
  const sortConfig: string[] | undefined = data?.queryConfig?.orderBy?.split(/%20| /);

  const onSort = async (value: string) => {
    if (!dispatchAction) return;
    try {
      setSortingCurrent(value);
      const order = sortConfig && sortConfig[0] === value ? (sortConfig[1] === 'asc' ? 'desc' : 'asc') : 'asc';
      const query = { orderBy: `${value} ${order}` };
      await dispatchAction(query);
      setSortingCurrent('');
    } catch (err: any) {
      notify.error(err.message);
      setSortingCurrent('');
    }
  };

  const onSearch = async (value: string) => {
    if (!dispatchAction) return;
    try {
      setIsSearchLoading(true);
      const query = { pageNumber: 1, searchQuery: value };
      await dispatchAction(query);
      setIsSearchLoading(false);
    } catch (err: any) {
      notify.error(err.message);
      setIsSearchLoading(false);
    }
  };

  const getDataValue = (item: T, dataIndex: string | ((item: T) => string)): any => {
    if (typeof dataIndex === 'function') {
      return dataIndex(item);
    }
    return item[dataIndex as keyof T] as unknown as string;
  };

  const getFuncValue = (func: ((item: any) => any) | string | boolean | undefined, item?: T): any => {
    if (typeof func === 'function') {
      return func(item);
    }
    return func;
  };

  const getSortingIcons = (sortable: string) => {
    if (sortingCurrent === sortable) {
      return <LoaderIcon className={styles.tableColSortingLoad} />;
    }
    if (sortConfig?.[0] === sortable) {
      if (sortConfig?.[1] === 'asc') {
        return <ArrowDownIcon className={cn(styles.tableColSortingIcon, styles.iconActive)} />;
      }
      return <ArrowUpIcon className={cn(styles.tableColSortingIcon, styles.iconActive)} />;
    }
    return <ArrowDownIcon className={styles.tableColSortingIcon} />;
  };

  const isAllChecked = () => {
    if (data?.queryConfig?.searchQuery && !data.data?.length) return false;

    const selectedItemsIds: string[] = selectedItems.map((i: T) => i[dataKey as keyof T] as unknown as string);
    let currentPageItemsIds: string[] = data?.data?.map((i: T) => i[dataKey as keyof T] as unknown as string) || [];

    if (ruleForToggleAll) {
      currentPageItemsIds = ruleForToggleAll;
    }

    const allSelected =
      currentPageItemsIds.length >= 1 ? currentPageItemsIds.every((i) => selectedItemsIds.includes(i)) : false;
    const someSelected =
      currentPageItemsIds.length >= 1 ? currentPageItemsIds.some((i) => selectedItemsIds.includes(i)) : false;

    if (allSelected) {
      return 'checked'; // All items are selected
    }
    if (someSelected) {
      return 'partial'; // Some items are selected
    }
    return 'empty'; // No items are selected
  };

  const isOneChecked = (item: T) =>
    !!selectedItems.find(
      (value) => (value[dataKey as keyof T] as unknown as string) === (item[dataKey as keyof T] as unknown as string),
    );

  const onToggleOne = (checked: boolean, item: T) => {
    if (checked) {
      if (setSelectedItems) {
        setSelectedItems([...selectedItems, item]);
      }
    } else {
      const selectedItemsNew = selectedItems.filter(
        (selected) =>
          (selected[dataKey as keyof T] as unknown as string) !== (item[dataKey as keyof T] as unknown as string),
      );
      if (setSelectedItems) {
        setSelectedItems(selectedItemsNew);
      }
    }
  };

  const onToggleAll = (isChecked: boolean) => {
    if (!data?.data?.length) return false;

    let selectedItemsNew = selectedItems.map((i) => ({ ...i }));

    if (isChecked) {
      data.data.forEach((a) => {
        if (
          !selectedItemsNew.some(
            (i) => (i[dataKey as keyof T] as unknown as string) === (a[dataKey as keyof T] as unknown as string),
          ) &&
          (typeof ruleForSelectItems === 'function' ? ruleForSelectItems(a) : true)
        ) {
          selectedItemsNew.push(a);
        }
      });
    } else {
      selectedItemsNew = selectedItemsNew.filter(
        (s) =>
          !data.data?.some(
            (i) => (i[dataKey as keyof T] as unknown as string) === (s[dataKey as keyof T] as unknown as string),
          ),
      );
    }

    if (setSelectedItems) {
      setSelectedItems(selectedItemsNew);
    }
  };

  const onToggleMainCheckbox = () => {
    const isChecked = isAllChecked() === 'empty';
    onToggleAll(isChecked);
  };

  const renderMainCheckbox = () => {
    const allChecked = isAllChecked();

    if (data?.queryConfig?.searchQuery && !data?.data?.length) return null;

    if (allChecked === 'checked') {
      return <Checkbox checked onChange={onToggleMainCheckbox} className={styles.tableCheckbox} />;
    }
    if (allChecked === 'partial') {
      return <Checkbox checked="partial" onChange={onToggleMainCheckbox} className={styles.tableCheckbox} />;
    }
    return <Checkbox checked={false} onChange={onToggleMainCheckbox} className={styles.tableCheckbox} />;
  };

  return (
    <>
      <table
        className={cn(styles.table, {
          [styles.full]: data?.data && data?.data?.length > 6,
          [styles.noData]: !data?.data?.length && !data?.queryConfig?.searchQuery && !!noData,
          [styles.loading]: isLoading,
          [className || '']: !!className,
        })}
        id={id}>
        {!data?.data?.length && !data?.queryConfig?.searchQuery && !!noData && !noDataOnlyBody ? null : (
          <thead>
            <tr className={styles.tableRow}>
              {columns.map(
                ({
                  name,
                  nameFilter,
                  sortable,
                  search,
                  searchNotDebounce,
                  checkbox,
                  tooltip,
                  width,
                  disabled,
                  extraContent,
                }) =>
                  !disabled && (
                    <th
                      key={name}
                      className={cn(styles.tableCol, styles.tableColHead, { [styles.tableColSortable]: !!sortable })}
                      style={{ width: getFuncValue(width) }}>
                      {checkbox && renderMainCheckbox()}
                      {sortable ? (
                        <span
                          className={styles.tableColSorting}
                          data-tip={tooltip && tooltip?.tip}
                          data-for={tooltip && tooltip?.id}
                          data-iscapture={tooltip && true}>
                          {tooltip ? (
                            <>
                              <button
                                type="button"
                                onClick={() => data?.data?.length && onSort(sortable)}
                                className={styles.tableColSortingButton}>
                                {name}
                                {getSortingIcons(sortable)}
                              </button>
                              {nameFilter && nameFilter}
                              {extraContent && extraContent}
                              <ReactTooltip
                                id={tooltip?.id}
                                place="right"
                                type="light"
                                effect="solid"
                                multiline
                                delayShow={200}
                              />
                            </>
                          ) : (
                            <>
                              <button
                                type="button"
                                onClick={() => data?.data?.length && onSort(sortable)}
                                className={styles.tableColSortingButton}>
                                {name}
                                {getSortingIcons(sortable)}
                              </button>
                              {nameFilter && nameFilter}
                              {extraContent && extraContent}
                            </>
                          )}
                        </span>
                      ) : (
                        name
                      )}
                      {search && (
                        <div className={styles.tableColSearch}>
                          <SearchInput
                            id={`search-by-${search.split(' ').join('-').toLowerCase()}`}
                            isLoading={isSearchLoading}
                            searchBy={search}
                            isNotDebounce={!!searchNotDebounce}
                            onChange={(value) => (searchNotDebounce ? searchNotDebounce(value) : onSearch(value))}
                          />
                        </div>
                      )}
                    </th>
                  ),
              )}
            </tr>
          </thead>
        )}
        <tbody>
          {!data?.data?.length && !data?.queryConfig?.searchQuery && !!noData ? (
            <tr className={styles.tableNoData}>
              <td>{noData}</td>
            </tr>
          ) : !data?.data?.length && !!data?.queryConfig?.searchQuery ? (
            <tr className={styles.tableNoData}>
              <td>No results found</td>
            </tr>
          ) : (
            data?.data?.map((item) => (
              <tr
                className={cn(styles.tableRow, styles.tableRowBody, {
                  [styles.tableRowBodySelected]: isOneChecked && isOneChecked(item),
                  [getFuncValue(classNameRow, item)]: getFuncValue(classNameRow, item),
                })}
                key={item[dataKey as keyof T] as unknown as string}
                onClick={() => onClickRow && onClickRow(item)}
                onMouseEnter={onMouseEnter ? (e: any) => onMouseEnter(e, item) : undefined}
                onMouseLeave={onMouseLeave ? (e: any) => onMouseLeave(e, item) : undefined}
                data-tip={getFuncValue(rowTip, item)}
                data-for="info-tooltip">
                {Object.values(columns).map(
                  (value) =>
                    !value.disabled && (
                      <td
                        className={cn(styles.tableCol, styles.tableColBody)}
                        style={{ width: getFuncValue(value.width, item) }}
                        key={value.name}>
                        {getFuncValue(value?.checkbox, item) && (
                          <Checkbox
                            checked={isOneChecked(item)}
                            onChange={(e) => onToggleOne(e.target.checked, item)}
                            className={styles.tableCheckbox}
                            isDisabled={getFuncValue(value?.checkboxDisabled, item)}
                          />
                        )}
                        {truncateString(getDataValue(item, value.data))}
                      </td>
                    ),
                )}
              </tr>
            ))
          )}
        </tbody>
      </table>
      {pagination && !!data?.pageMeta?.TotalCount && data.pageMeta.TotalCount > 10 && dispatchAction && (
        <Pagination pageMeta={data.pageMeta} queryConfig={data.queryConfig} paginationAction={dispatchAction} />
      )}
    </>
  );
};

export default Table;

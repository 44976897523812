import { corpAdmin, corpTechAdmin, custAdmin, spAdmin, spTechAdmin, techAdmin } from 'consts';
import { IDomain } from 'interfaces';
import { IMyDomainMachines } from 'store/slices/workspace';

export const checkIsNoMachines = (custMachines: IMyDomainMachines, isDomainAzure: boolean) => {
  const isNoMachines = isDomainAzure
    ? !custMachines?.data.length && !custMachines?.queryConfig.searchQuery
    : !custMachines?.data.length && !custMachines?.queryConfig.searchQuery && !custMachines?.pageMeta.AllMachinesCount;

  return isNoMachines;
};

export const checkShowSentryUpdateAlert = (
  domain: IDomain,
  userRole: string,
  showSentryUpdateMessageForAdAdmins: boolean,
) => {
  const hasOutdatedSentry = domain.sentries?.some((sentry) => sentry.isOutdated);

  const notShownForAdAdmin =
    [spTechAdmin, custAdmin, techAdmin].includes(userRole) && !showSentryUpdateMessageForAdAdmins;

  const showSentryUpdateAlert =
    [spAdmin, spTechAdmin, custAdmin, techAdmin].includes(userRole) && hasOutdatedSentry && !notShownForAdAdmin;

  return showSentryUpdateAlert;
};

export const checkIsShowADStatus = (domain: IDomain, userRole: string) => {
  return !!(domain.status && [spAdmin, corpAdmin, spTechAdmin, corpTechAdmin].includes(userRole));
};

export const checkIsShowAlertNoSentryInstalled = (
  userRole: string,
  isDomainAzure: boolean,
  custMachines: IMyDomainMachines,
  sentryDeleted: boolean,
) => {
  const showNoSentryInstalledAlert =
    [spAdmin, spTechAdmin].includes(userRole) &&
    !isDomainAzure &&
    !!custMachines.pageMeta?.AllMachinesCount &&
    sentryDeleted;
  return showNoSentryInstalledAlert;
};

export const isShowAllHeader = (
  isDomainAzure: boolean,
  isDomainHybryd: boolean,
  isDomainOnPremise: boolean,
  sentryDeleted: boolean,
) => {
  return isDomainAzure || ((isDomainHybryd || isDomainOnPremise) && sentryDeleted);
};

export const checkIsAdminRole = (userRole: string) => {
  const isAdminRole = [spAdmin, spTechAdmin, custAdmin, techAdmin].includes(userRole);
  return isAdminRole;
};

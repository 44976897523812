import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { http, createHttpRequestInitResult, createExtraReducersForResponses } from 'helpers/http';
import { IHttpRequestResult } from 'interfaces';

interface IGetPassMgmtPayload {
  domainId: string;
  _background?: boolean;
}

export interface IGetPassMgmtData {
  isAnyUser: boolean;
  isEnabled: boolean;
  shouldUpdateSentry: boolean;
}

export const getPassMgmtData = createAsyncThunk(
  'phoneSetup/getResetPhoneData',
  async ({ domainId, _background }: IGetPassMgmtPayload) => {
    const response: AxiosResponse<IGetPassMgmtData> = await http.get(
      `/api/SecurityManagement/AdPasswordReset/GetPasswordResetState?domainId=${domainId}`,
    );
    if (response.status === 403) {
      return { isAnyUser: false, isEnabled: false, shouldUpdateSentry: true };
    }
    const data: IGetPassMgmtData = response.data;
    return data;
  },
);

export const togglePassMgmtStatus = createAsyncThunk(
  'phoneSetup/togglePhoneSetupStatus',
  async ({ domainId }: { domainId: string }) => {
    const response: AxiosResponse = await http.post('/api/SecurityManagement/AdPasswordReset/ChangeStatus', domainId);
    const data: IGetPassMgmtData = response.data;
    return data;
  },
);

interface PassMgmtState {
  passMgmtState: IGetPassMgmtData | null;
  getPassMgmtDataRequest: IHttpRequestResult<IGetPassMgmtData>;
  togglePassMgmtStatusRequest: IHttpRequestResult<IGetPassMgmtData>;
}

const initialState: PassMgmtState = {
  passMgmtState: null,
  getPassMgmtDataRequest: createHttpRequestInitResult(),
  togglePassMgmtStatusRequest: createHttpRequestInitResult(),
};

export const passMgmtSlice = createSlice({
  name: 'passMgmt',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    createExtraReducersForResponses<PassMgmtState>(
      builder,
      getPassMgmtData,
      'getPassMgmtDataRequest',
      (state, { payload }) => {
        state.passMgmtState = payload;
      },
    );
    createExtraReducersForResponses<PassMgmtState>(
      builder,
      togglePassMgmtStatus,
      'togglePassMgmtStatusRequest',
      (state, { payload }) => {
        state.passMgmtState = payload;
      },
    );
  },
});

// export const {} = passMgmtSlice.actions;

export default passMgmtSlice.reducer;

import { useEffect, useState } from 'react';
import { Loader } from 'components';
import { notify, truncateString } from 'utils';
import EnableToggleModal from './components/EnableToggleModal/EnableToggleModal';
import NoUsers from './components/NoUsers/NoUsers';
import RememberMeStatus from './components/RememberMeStatus/RememberMeStatus';
import RememberMeTitle from './components/RememberTitle/RememberTitle';
import RememberMeHeader from './components/RememberMeHeader/RememberMeHeader';
import RememberDropdownTable from './components/RememberDropdownTable/RememberDropdownTable';
import EnableRememberMeForUserModal from './components/EnableRememberMeForUserModal/EnableRememberMeForUserModal';
import HeaderActions from './components/HeaderAction/HeaderActions';
import { IDomain } from 'interfaces';
import { useAppDispatch, useAppSelector } from 'store';
import { IRememberMeUser, getRememberMeData, onChangeRememberMeStatus } from 'store/slices/securityMgmt/remebmerMe';
import { getActionType, shouldShowEnableRememberMeForUserModal } from './utils/rememberMeUtils';
import styles from './RememberMe.module.scss';

interface Props {
  domain: IDomain;
  isCompanyPage: boolean;
}

const RememberMe = ({ domain, isCompanyPage }: Props) => {
  const { isLoading } = useAppSelector((state) => state.rememberMe.getRememberMeDataRequest);
  const { rememberMeData } = useAppSelector((state) => state.rememberMe);
  const { isNoData } = useAppSelector((state) => state.rememberMe.rememberMeData) || {};
  const isRememberMeEnabled = rememberMeData?.isRememberMeEnabled || false;
  const dispatch = useAppDispatch();

  const [selectedUsers, setSelectedUsers] = useState<IRememberMeUser[]>([]);

  const [userWhoseStatusChanging, setUserWhoseStatusChanging] = useState<string>('');
  const [isHeaderLoadingChanging, setIsHeaderLoadingChanging] = useState<boolean>(false);

  const [isShowEnableRememberMeForUser, setIsShowEnableRememberMeForUser] = useState(false);
  const [confirmModalData, setConfirmModalData] = useState<IRememberMeUser | IRememberMeUser[] | null>(null);
  const handleShowEnableRememberMeForUser = (user: IRememberMeUser | IRememberMeUser[]) => {
    setConfirmModalData(user);
    setIsShowEnableRememberMeForUser(true);
  };
  const handleCloseEnableRememberMeForUser = () => {
    setIsShowEnableRememberMeForUser(false);
    setConfirmModalData(null);
  };

  const [isShowToggleModal, setIsShowToggleModal] = useState(false);
  const handleShowToggleModal = () => {
    setIsShowToggleModal(!isShowToggleModal);
  };

  const fetchData = async () => {
    const queryConfig = {
      pageNumber: 1,
      pageSize: 10,
      searchQuery: '',
      orderBy: 'displayName asc',
      viewBy: 'All',
    };
    try {
      await dispatch(getRememberMeData({ domainId: domain.id, query: queryConfig })).unwrap();
    } catch (err: any) {
      notify.error(err.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleChangeRememberMeForUser = async (isHeaderBtn: boolean, user?: IRememberMeUser) => {
    const selectedUserIds = isHeaderBtn ? selectedUsers.map((item) => item.userId) : [user!.userId];
    if (isHeaderBtn) {
      const actionType = getActionType(selectedUsers);
      const showEnableRememberMeForUserModal = shouldShowEnableRememberMeForUserModal(selectedUsers);
      if (showEnableRememberMeForUserModal) {
        return handleShowEnableRememberMeForUser(selectedUsers);
      }
      setIsHeaderLoadingChanging(true);
      await dispatch(onChangeRememberMeStatus({ domainId: domain.id, selectedUserIds })).unwrap();
      if (selectedUsers.length > 1) {
        notify.success(`Remember Me is ${actionType} for selected users`);
      } else {
        notify.success(`Remember Me is ${actionType} for ${truncateString(selectedUsers[0].displayName, false)}`);
      }
      setIsHeaderLoadingChanging(false);
    }
    if (!isHeaderBtn) {
      if (user?.isRememberMeEnabled) {
        setUserWhoseStatusChanging(user.userId);
        await dispatch(onChangeRememberMeStatus({ domainId: domain.id, selectedUserIds })).unwrap();
        notify.success(`Remember Me is disabled for ${truncateString(user.displayName, false)}`);
        setUserWhoseStatusChanging('');
      } else if (!user?.isMfaEnabled) {
        handleShowEnableRememberMeForUser(user!);
      } else {
        setUserWhoseStatusChanging(user.userId);
        await dispatch(onChangeRememberMeStatus({ domainId: domain.id, selectedUserIds })).unwrap();
        notify.success(`Remember Me is enabled for ${truncateString(user.displayName, false)}`);
        setUserWhoseStatusChanging('');
      }
    }
  };

  return (
    <div className={styles.remember}>
      <RememberMeTitle isCompanyPage={isCompanyPage} domain={domain} />
      {isLoading ? (
        <Loader id="loading-remember-me" />
      ) : isNoData ? (
        <NoUsers />
      ) : (
        <>
          <RememberMeStatus
            handleShowToggleModal={handleShowToggleModal}
            isRememberMeEnabled={isRememberMeEnabled}
            selectedUsers={selectedUsers}
          />
          {!isRememberMeEnabled ? (
            <RememberMeHeader />
          ) : (
            <>
              {selectedUsers.length > 0 && (
                <HeaderActions
                  selectedUsers={selectedUsers}
                  setSelectedUsers={setSelectedUsers}
                  handleChangeRememberMeForUser={handleChangeRememberMeForUser}
                  isHeaderLoadingChanging={isHeaderLoadingChanging}
                />
              )}
              <RememberDropdownTable
                domainId={domain.id}
                handleChangeRememberMeForUser={handleChangeRememberMeForUser}
                selectedUsers={selectedUsers}
                setSelectedUsers={setSelectedUsers}
                userWhoseStatusChanging={userWhoseStatusChanging}
              />
            </>
          )}
          {isShowToggleModal && (
            <EnableToggleModal
              isShowToggleModal={isShowToggleModal}
              handleShowToggleModal={handleShowToggleModal}
              domainId={domain.id}
            />
          )}
          {isShowEnableRememberMeForUser && (
            <EnableRememberMeForUserModal
              isOpen={isShowEnableRememberMeForUser}
              onRequestClose={handleCloseEnableRememberMeForUser}
              confirmModalData={confirmModalData!}
              selectedUsers={selectedUsers}
              domainId={domain.id}
            />
          )}
        </>
      )}
    </div>
  );
};

export default RememberMe;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IHttpRequestResult } from 'interfaces/api';
import { http, createHttpRequestInitResult, createExtraReducersForResponses } from 'helpers/http';
import { IPageMeta, IQueryConfig } from 'interfaces';
import { queryFormatter } from 'utils';
import { AxiosResponse } from 'axios';
import { AsyncThunkConfig, GetThunkAPI } from '@reduxjs/toolkit/dist/createAsyncThunk';
import { RootState } from 'store/store';

export interface IMfaUser {
  dateOfReset: Date | null;
  userId: string;
  displayName: string;
  upn: string;
  userEmail: string;
  phoneCountryCode: string;
  phoneNumber: string;
  setUpDate: Date | null;
  isMfaSet: boolean;
  isAdmin: boolean;
  promotedFromRolesMngmt: boolean;
  promotedFromAdFederation: boolean;
  mfaStatus: number;
  mfaType: number;
}

export interface IMfaData {
  data?: IMfaUser[];
  pageMeta?: IPageMeta;
  queryConfig?: IQueryConfig;
  isNoData?: boolean;
}

interface IGetMfaDataPayload {
  query?: IQueryConfig;
  domainId: string;
  _background?: boolean;
}

interface IChangeUserMfaStatusPayload {
  users: IMfaUser[];
  domainId: string;
  isResetMfa?: boolean;
  isEnableMfa: boolean;
  isResetPhone: boolean;
}

export const getMfaData = createAsyncThunk(
  'mfaMgmt/getMfaData',
  async ({ query, domainId, _background = false }: IGetMfaDataPayload, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const queryConfig = state.mfaMgmt.mfaData?.queryConfig || {};
    const queryConfigNew = { ...queryConfig, ...query };
    const baseUrl = `/api/SecurityManagement/Mfa/Get?domainId=${domainId}`;
    const response: AxiosResponse = await http.get(queryFormatter(baseUrl, queryConfigNew));
    const pageMeta: IPageMeta = response?.headers['x-pagination'] ? JSON.parse(response.headers['x-pagination']) : null;
    if (response.status === 204) {
      const data: IMfaData = {
        isNoData: true,
      };
      return data;
    } else {
      const data: IMfaData = {
        data: response.data,
        pageMeta,
        queryConfig: queryConfigNew,
      };
      return data;
    }
  },
);

export const changeUserMfaStatus = createAsyncThunk(
  'mfaMgmt/changeUserMfaStatus',
  async (
    { users, domainId, isResetMfa, isEnableMfa, isResetPhone }: IChangeUserMfaStatusPayload,
    thunkAPI: GetThunkAPI<AsyncThunkConfig>,
  ) => {
    const url = isResetMfa
      ? `/api/SecurityManagement/Mfa/ResetMfa?resetPhone=${isResetPhone}`
      : `/api/SecurityManagement/Mfa/ChangeMfaStatus?switchState=${isEnableMfa}`;
    await http.post(url, users);
    const state: RootState = thunkAPI.getState() as RootState;
    const { queryConfig } = state.mfaMgmt.mfaData;
    const query = { ...queryConfig, pageNumber: 1 };
    await thunkAPI.dispatch(getMfaData({ domainId, query, _background: true }));
  },
);

interface IMfaMgmtState {
  mfaData: IMfaData;
  getMfaDataRequest: IHttpRequestResult<IMfaData>;
  changeUserMfaStatusRequest: IHttpRequestResult<void>;
}

const initialState: IMfaMgmtState = {
  mfaData: {},
  getMfaDataRequest: createHttpRequestInitResult(),
  changeUserMfaStatusRequest: createHttpRequestInitResult(),
};

export const mfaMgmtSlice = createSlice({
  name: 'mfaMgmt',
  initialState,
  reducers: {
    updateMfaData: (state, action) => {
      state.mfaData = action.payload;
    },
  },
  extraReducers: (builder) => {
    createExtraReducersForResponses<IMfaMgmtState>(builder, getMfaData, 'getMfaDataRequest', (state, action) => {
      state.mfaData = action.payload;
    });
    createExtraReducersForResponses<IMfaMgmtState>(builder, changeUserMfaStatus, 'changeUserMfaStatusRequest');
  },
});

export const { updateMfaData } = mfaMgmtSlice.actions;

export default mfaMgmtSlice.reducer;
